<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="3" lg="2">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Start Date"
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="2">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="End Date"
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="2" lg="1">
          <v-select
            label="Currency"
            outlined
            flat
            dense
            background-color="white"
            :items="dropDownCurrency"
            v-model="params.currency"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="2">
          <v-btn @click="search" color="primary" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn @click="excel" color="success">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">No</th>
              <th class="text-center">Customer Name</th>
              <th class="text-center">(1) 0</th>
              <th class="text-center">(2) 1 - 30</th>
              <th class="text-center">(3) 31 - 60</th>
              <th class="text-center">(4) > 60</th>
              <th class="text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="`${index}-A`">
              <td class="text-center">{{ index + 1 }}</td>
              <td>{{ item.customerName }}</td>
              <td class="text-right">{{ formatPrice(item.rangeZero) }}</td>
              <td class="text-right">{{ formatPrice(item.rangeOne) }}</td>
              <td class="text-right">{{ formatPrice(item.rangeTwo) }}</td>
              <td class="text-right">{{ formatPrice(item.rangeThree) }}</td>
              <td class="text-right">{{ formatPrice(item.total) }}</td>
            </tr>
            <tr>
              <td colspan="2" class="text-center">Total</td>
              <td class="text-right">{{ formatPrice(totalRangeZero) }}</td>
              <td class="text-right">{{ formatPrice(totalRangeOne) }}</td>
              <td class="text-right">{{ formatPrice(totalRangeTwo) }}</td>
              <td class="text-right">{{ formatPrice(totalRangeThree) }}</td>
              <td class="text-right">{{ formatPrice(totalRangeTotal) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import fileDownload from "js-file-download";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "report-ar-summary",
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      params: {
        startDate: moment()
          .startOf("month")
          .format("yyyy-MM-DD"),
        endDate: moment().format("yyyy-MM-DD"),
        currency: "IDR",
      },
      items: [],
    };
  },
  computed: {
    ...mapState("currency", ["dropDownCurrency"]),
    totalRangeZero() {
      if (this.items.length > 0) {
        return this.items.map(x => x.rangeZero).reduce((total, current) => total + current);
      }
      return 0;
    },
    totalRangeOne() {
      if (this.items.length > 0) {
        return this.items.map(x => x.rangeOne).reduce((total, current) => total + current);
      }
      return 0;
    },
    totalRangeTwo() {
      if (this.items.length > 0) {
        return this.items.map(x => x.rangeTwo).reduce((total, current) => total + current);
      }
      return 0;
    },
    totalRangeThree() {
      if (this.items.length > 0) {
        return this.items.map(x => x.rangeThree).reduce((total, current) => total + current);
      }
      return 0;
    },
    totalRangeTotal() {
      if (this.items.length > 0) {
        return this.items.map(x => x.total).reduce((total, current) => total + current);
      }
      return 0;
    },
  },
  methods: {
    async search() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/arSummary", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([await this.$store.dispatch("currency/getListCurrency")])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/arSummaryExcel", this.params)
        .then(response => {
          fileDownload(response.data, `report-ar-summary.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid grey;
}
</style>
